module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TXDPSHVC","enableWebVitalsTracking":true,"defaultDataLayer":{"type":"function","value":"()=>({pageType:window.pageType})"},"includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vanguard Talent Management & Development, Inc.","short_name":"Vanguard Talent","start_url":"/","background_color":"#000","theme_color":"#000","display":"standalone","icon":"src/assets/images/vanguard-talent-management-and-development-inc.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"597a7b93e2407a5a6218b093f8fdf01c"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
